import { mdiChemicalWeapon, mdiFormatListText, mdiInformation, mdiSecurity, mdiWalletOutline } from '@mdi/js';

import { ADVERTISER, PUBLISHER } from '@/utils/constants';

export default {
  [ADVERTISER]: [
    {
      subheader: 'MANAGE',
    },
    {
      title: 'Offers',
      icon: mdiFormatListText,
      to: 'advertiser-offers',
    },
    {
      title: 'Sales & Commissions',
      icon: mdiFormatListText,
      to: 'advertiser-sales',
    },
    {
      title: 'Publishers',
      icon: mdiFormatListText,
      to: 'advertiser-publishers',
    },
    {
      subheader: 'SETTINGS',
    },
    {
      title: 'Company Settings',
      icon: mdiSecurity,
      to: 'advertiser-company-settings',
    },
    {
      subheader: 'TOOLS',
    },
    {
      title: 'Launchpad',
      icon: mdiChemicalWeapon,
      children: [
        {
          title: 'Assets',
          to: 'advertiser-launchpad-assets',
        },
        {
          title: 'Assets Report',
          to: 'advertiser-launchpad-assets-report',
        }
      ]
    },
    {
      subheader: 'ADMIN',
      isAdmin: true,
    },
    {
      title: 'System',
      icon: mdiFormatListText,
      isAdmin: true,
      children: [
        {
          title: 'Users',
          to: 'admin-system-users',
        },
        {
          title: 'Projects',
          to: 'admin-system-projects',
        },
        {
          title: 'Offers',
          to: 'admin-system-offers',
        }
      ]
    },
    {
      title: 'Launchpad',
      icon: mdiFormatListText,
      isAdmin: true,
      children: [
        {
          title: 'Assets',
          to: 'admin-launchpad-assets',
        },
        {
          title: 'Assets Review',
          to: 'admin-launchpad-review',
        }
      ]
    },
    {
      subheader: 'RESOURCES',
    },
    {
      title: 'Docs',
      icon: mdiInformation,
      href: 'https://docs.nftiz.com',
    },
  ],
  [PUBLISHER]: [
    {
      subheader: 'MANAGE',
    },
    {
      title: 'Sales',
      icon: mdiFormatListText,
      to: 'publisher-sales',
    },
    {
      title: 'Offers',
      icon: mdiFormatListText,
      to: 'publisher-offers',
    },
    {
      title: 'Marketplace',
      icon: mdiWalletOutline,
      to: 'publisher-marketplace',
    },
  ]
}
