<template>
  <div class="vertical-nav-header d-flex flex-column align-center justify-center ps-6 pe-5 pt-5 pb-2">
    <router-link
      to="/"
      class="d-flex align-center text-decoration-none flex-column"
    >
      <v-slide-x-transition>
        <svg-changer class="app-logo mx-auto d-block">
          <app-logo></app-logo>
        </svg-changer>
      </v-slide-x-transition>

      {{dashboardName}} Dashboard
    </router-link>

    <!-- <v-slide-x-transition>
      <div
        v-show="!(menuIsVerticalNavMini && !isMouseHovered)"
        v-if="$vuetify.breakpoint.lgAndUp"
        class="d-flex align-center ms-1"
        @click.stop="menuIsVerticalNavMini = !menuIsVerticalNavMini"
      >
        <v-icon
          v-show="!menuIsVerticalNavMini"
          size="20"
          class="cursor-pointer"
        >
          {{ icons.mdiRecordCircleOutline }}
        </v-icon>
        <v-icon
          v-show="menuIsVerticalNavMini"
          size="20"
          class="cursor-pointer"
        >
          {{ icons.mdiRadioboxBlank }}
        </v-icon>
      </div>
      <v-icon
        v-else
        size="20"
        class="d-inline-block"
        @click.stop="$emit('close-nav-menu')"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </v-slide-x-transition> -->
  </div>
</template>

<script>
import { mdiRadioboxBlank, mdiRecordCircleOutline, mdiClose } from '@mdi/js'
import useLayoutConfig from '@/composable/useLayoutConfig'
import themeConfig from '@themeConfig'
import { inject } from '@vue/composition-api'

import { ADVERTISER, PUBLISHER } from '@/utils/constants'

import AppLogo from '@/assets/images/svg/logo.svg'

export default {
  components: {
    AppLogo,
  },
  setup() {
    const { menuIsVerticalNavMini } = useLayoutConfig()
    const isMouseHovered = inject('isMouseHovered')

    return {
      menuIsVerticalNavMini,
      isMouseHovered,
      appName: themeConfig.app.name,

      // Icons
      icons: {
        mdiClose,
        mdiRadioboxBlank,
        mdiRecordCircleOutline,
      },
    }
  },
  computed: {
    dashboardName() {
      const names = {
        [ADVERTISER]: 'Advertiser',
        [PUBLISHER]: 'Publisher',
      }

      return names[this.$route.meta.role]
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}
</style>
