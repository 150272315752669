<template>
  <div class="connect-wrapper">
    <v-btn
      v-if="!isAuthenticated"
      color="primary"
      class="white--text"
      :disabled="connecting"
      :loading="connecting"
      @click="connect"
      text
      outlined
      small
    >
      <v-img
        width="16px"
        class="mr-2"
        :src="require(`@/assets/images/svg/metamask.svg`)"
      ></v-img>
      Connect
    </v-btn>
    <v-menu
      v-else
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          dot
        >
          <v-avatar
            size="40px"
            v-bind="attrs"
            color="primary"
            class="v-avatar-light-bg primary--text"
            v-on="on"
          >
            <img
              :src="projectLogo || require(`@/assets/images/avatars/3.png`)"
              alt="Project Logo"
            >
            <!-- <v-icon size="25">{{icons.mdiAccountOutline}}</v-icon> -->
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge
            bottom
            color="success"
            overlap
            offset-x="12"
            offset-y="12"
            class="ms-4"
            dot
          >
            <v-avatar
              size="40px"
              color="primary"
              class="v-avatar-light-bg primary--text"
            >
              <v-icon size="25">{{icons.mdiAccountOutline}}</v-icon>
            </v-avatar>
          </v-badge>
          <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align:middle"
          >
            <span class="text--primary font-weight-semibold mb-n1 account-wallet">
              {{web3activeAccount}}
            </span>
            <small class="text--disabled text-capitalize">Wallet Address</small>
          </div>
        </div>

        <div v-if="roleSwitcher" class="d-flex justify-center mb-2">
          <role-switcher></role-switcher>
        </div>

        <v-divider></v-divider>

        <!-- Logout -->
        <v-list-item @click="logout">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mdiAccountOutline, mdiLogoutVariant } from '@mdi/js'

import RoleSwitcher from '@/components/ui/role-switcher/RoleSwitcher'

export default {
  components: {
    RoleSwitcher,
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiLogoutVariant,
      },
    }
  },
  props: {
    roleSwitcher: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('web3auth', ['isAuthenticated', 'userdata']),
    ...mapState({
      connecting: state => state.web3auth.connecting,
      web3activeAccount: state => state.web3auth.account,
    }),
    projectLogo() {
      return this.$route.meta.role === 'advertiser' && this.userdata?.project?.logo
    },
  },
  methods: {
    async connect() {
      await this.$store.dispatch('web3auth/CONNECT')
    },
    logout() {
      if (process.env.VUE_APP_REDIRECT_URI) {
        this.$store.commit('web3auth/PURGE_AUTH')
        window.location.href = process.env.VUE_APP_REDIRECT_URI
      } else {
        this.$router.push({ name: 'login' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.account-wallet {
  text-overflow: ellipsis;
  max-width: 126px;
  overflow: hidden;
}
</style>
