import { mdiFormatListText, mdiWalletOutline } from '@mdi/js';

import { ADVERTISER, PUBLISHER } from '@/utils/constants';

export default {
  [ADVERTISER]: [
    {
      title: 'Offers',
      icon: mdiFormatListText,
      to: 'advertiser-offers',
    },
    {
      title: 'Sales & Commissions',
      icon: mdiFormatListText,
      to: 'advertiser-sales',
    },
  ],
  [PUBLISHER]: [
    {
      title: 'Sales',
      icon: mdiFormatListText,
      to: 'publisher-sales',
    },
    {
      title: 'Offers',
      icon: mdiFormatListText,
      to: 'publisher-offers',
    },
    {
      title: 'Marketplace',
      icon: mdiWalletOutline,
      to: 'publisher-marketplace',
    },
  ]
}
