import store from '../store';

import { ADVERTISER } from '@/utils/constants';
import { validateUserByRole } from '@/utils/user';

const REDIRECT_URI = process.env.VUE_APP_REDIRECT_URI;

export const validateRouteWithAuthData = async (to, from, next) => {
  if (to.name === 'login') {
    store.commit('app/HIDE_FULL_PAGE_LOADER');
    store.commit('web3auth/PURGE_AUTH');

    return next();
  }

  if (!store.getters['web3auth/userdata']._id || from.meta.onboarding) {
    await store.dispatch('web3auth/CONNECT', { auto: true });
  }

  store.commit('app/HIDE_FULL_PAGE_LOADER');

  const userdata = store.getters['web3auth/userdata'];

  if (REDIRECT_URI && to.path == '/' && !userdata._id) {
    return window.location.href = REDIRECT_URI;
  }

  if ((to.meta.role && !userdata._id && to.name !== 'login') || (to.meta.onboarding && !userdata._id)) {
    return next({
      name: 'login',
      query: { role: to.meta.role || '' }
    });
  }

  if (userdata._id) {
    const onboardingPage = to.meta.onboarding;

    if (to.meta.role && onboardingPage) {
      const valid = validateUserByRole(userdata, to.meta.role);

      if (valid) {
        return next({ name: to.meta.role });
      }
    } else if (to.meta.role && !onboardingPage) {
      const onbordingRequired = to.meta.onbordingRequired;

      if (!onbordingRequired) return next();
  
      const valid = validateUserByRole(userdata, to.meta.role);
  
      if (!valid) {
        return next({ name: `${to.meta.role}-onboarding` });
      }

      if (to.meta.isAdmin && !userdata.isAdmin) {
        return next({ name: userdata.role || ADVERTISER });
      }
    }

    if (to.path == '/') return next({ name: userdata.role || ADVERTISER })
  }

  if (to.path == '/') return next({ name: 'login' });

  next()
};
