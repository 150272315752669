<template>
  <div class="v-application" :class="{ 'theme--dark': darkTheme }">
    <div class="v-application--wrap">
      <div class="content">
        <div class="fullpage-loader">
          <svg-changer width="180px" class="app-logo mx-auto d-block mb-0">
            <app-logo></app-logo>
          </svg-changer>

          <span class="loading-text">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLogo from '@/assets/images/svg/logo.svg'

export default {
  components: {
    AppLogo,
  },
  computed: {
    darkTheme() {
      return this.$vuetify.theme.isDark
    },
  },
}
</script>

<style lang="scss" scoped>
// .wrapper {
//   flex: 1 1 auto;
//   backface-visibility: hidden;
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh;
//   max-width: 100%;
//   position: relative;
// }

.content {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.fullpage-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loading-text {
  margin-top: 3px;
  font-size: 18px;
  color: var(--v-primary-base);
}
</style>
