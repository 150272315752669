<template>
  <v-select
    label="Dashboard"
    v-model="role"
    :items="items"
    hide-details
    outlined
    item-text="name"
    item-value="key"
    dense
    @change="onChange"
    color="primary"
    class="mb-0 pb-0"
  ></v-select>
</template>

<script>
import { ADVERTISER, PUBLISHER } from '@/utils/constants'

export default {
  data: () => ({
    role: null,
    items: [
      { key: ADVERTISER, name: 'Advertiser' },
      { key: PUBLISHER, name: 'Publisher' },
    ],
  }),
  created() {
    this.role = this.$route.meta.role
  },
  methods: {
    onChange() {
      this.$http.put('/user/role', { role: this.role }).catch(err => {
        console.log(err)
      })

      this.$router.push({ name: this.role })
    },
  },
}
</script>