import Vue from 'vue';
import VueRouter from 'vue-router';

import { ADVERTISER, PUBLISHER } from '@/utils/constants';
import { validateRouteWithAuthData } from './guard';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      layout: 'blank'
    },
  },
  {
    path: '/advertiser',
    name: 'advertiser',
    redirect: '/advertiser/offers'
  },
  {
    path: '/publisher',
    name: 'publisher',
    redirect: '/publisher/sales'
  },
  {
    path: '/advertiser/onboarding',
    name: 'advertiser-onboarding',
    component: () => import('@/views/advertiser/onboarding/AdvertiserOnboarding.vue'),
    meta: {
      layout: 'content-onboarding',
      onboarding: true,
      role: ADVERTISER
    },
  },
  {
    path: '/publisher/onboarding',
    name: 'publisher-onboarding',
    component: () => import('@/views/publisher/onboarding/PublisherOnboarding.vue'),
    meta: {
      layout: 'content-onboarding',
      onboarding: true,
      role: PUBLISHER
    },
  },
  {
    path: '/advertiser/offers',
    name: 'advertiser-offers',
    component: () => import('@/views/advertiser/offers/AdvertiserOffers.vue'),
    meta: {
      layout: 'content',
      role: ADVERTISER,
      onbordingRequired: true
    },
  },
  {
    path: '/advertiser/sales',
    name: 'advertiser-sales',
    component: () => import('@/views/advertiser/sales/AdvertiserSales.vue'),
    meta: {
      layout: 'content',
      role: ADVERTISER,
      onbordingRequired: true
    },
  },
  {
    path: '/advertiser/publishers',
    name: 'advertiser-publishers',
    component: () => import('@/views/advertiser/publishers/AdvertiserPublishers.vue'),
    meta: {
      layout: 'content',
      role: ADVERTISER,
      onbordingRequired: true
    },
  },
  {
    path: '/advertiser/company-settings',
    name: 'advertiser-company-settings',
    component: () => import('@/views/advertiser/company-settings/AdvertiserCompanySettings.vue'),
    meta: {
      layout: 'content',
      role: ADVERTISER,
      onbordingRequired: true
    },
  },
  {
    path: '/advertiser/launchpad/assets',
    name: 'advertiser-launchpad-assets',
    component: () => import('@/views/advertiser/assets/AdvertiserAssets.vue'),
    meta: {
      layout: 'content',
      role: ADVERTISER,
      onbordingRequired: true
    },
  },
  {
    path: '/advertiser/launchpad/assets-report',
    name: 'advertiser-launchpad-assets-report',
    component: () => import('@/views/advertiser/assets-report/AdvertiserAssetsReport.vue'),
    meta: {
      layout: 'content',
      role: ADVERTISER,
      onbordingRequired: true
    },
  },
  {
    path: '/publisher/sales',
    name: 'publisher-sales',
    component: () => import('@/views/publisher/sales/PublisherSales.vue'),
    meta: {
      layout: 'content',
      role: PUBLISHER,
      onbordingRequired: true
    },
  },
  {
    path: '/publisher/offers',
    name: 'publisher-offers',
    component: () => import('@/views/publisher/offers/PublisherOffers.vue'),
    meta: {
      layout: 'content',
      role: PUBLISHER,
      onbordingRequired: true
    },
  },
  {
    path: '/publisher/marketplace',
    name: 'publisher-marketplace',
    component: () => import('@/views/publisher/marketplace/PublisherMarketplace.vue'),
    meta: {
      layout: 'content',
      role: PUBLISHER,
      onbordingRequired: true
    },
  },
  {
    path: '/admin/system/users',
    name: 'admin-system-users',
    component: () => import('@/views/admin/users/AdminUsers.vue'),
    meta: {
      layout: 'content',
      role: ADVERTISER,
      isAdmin: true,
      onbordingRequired: true
    },
  },
  {
    path: '/admin/system/projects',
    name: 'admin-system-projects',
    component: () => import('@/views/admin/projects/AdminProjects.vue'),
    meta: {
      layout: 'content',
      role: ADVERTISER,
      isAdmin: true,
      onbordingRequired: true
    },
  },
  {
    path: '/admin/system/offers',
    name: 'admin-system-offers',
    component: () => import('@/views/admin/offers/AdminOffers.vue'),
    meta: {
      layout: 'content',
      role: ADVERTISER,
      isAdmin: true,
      onbordingRequired: true
    },
  },
  {
    path: '/admin/launchpad/review',
    name: 'admin-launchpad-review',
    component: () => import('@/views/admin/assets-review/AdminAssetsReview.vue'),
    meta: {
      layout: 'content',
      role: ADVERTISER,
      isAdmin: true,
      onbordingRequired: true
    },
  },
  {
    path: '/admin/launchpad/assets',
    name: 'admin-launchpad-assets',
    component: () => import('@/views/admin/assets/AdminAssets.vue'),
    meta: {
      layout: 'content',
      role: ADVERTISER,
      isAdmin: true,
      onbordingRequired: true
    },
  }
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
});

router.beforeEach(validateRouteWithAuthData);

export default router;
