<template>
  <v-app
    class="content-layout horizontal-nav"
    :class="{'content-full': appContentWidth === 'full'}"
  >
    <!-- Navbar -->
    <v-system-bar
      app
      height="64"
      :absolute="appBarType === 'static'"
      :class="[{'app-system-bar-boxed': appContentWidth === 'boxed'}, { 'bg-blur': appBarIsBlurred }]"
      class="app-system-bar"
    >
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mdAndDown"
          ></v-app-bar-nav-icon>
          <router-link
            to="/"
            class="d-flex align-center text-decoration-none"
          >
            <svg-changer class="me-3" height="30px" width="120px">
              <app-logo></app-logo>
            </svg-changer>
          </router-link>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center">

          <app-bar-i18n></app-bar-i18n>
          <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
      <v-overlay
        :value="$store.state.app.shallContentShowOverlay"
        z-index="5"
        absolute
        class="system-bar-overlay"
      ></v-overlay>
    </v-system-bar>

    <!-- Horizontal Nav Menu -->
    <v-app-bar
      v-if="!menuIsMenuHidden"
      class="navigation-menu"
      :class="{ 'bg-blur': appBarIsBlurred }"
      app
      height="64"
      :absolute="appBarType === 'static'"
      :elevation="$vuetify.theme.isDark ? 4 : 3"
    >
      <horizontal-nav-menu
        :nav-menu-items="menuItems"
        :class="{'horizontal-nav-menu-boxed mx-auto': appContentWidth === 'boxed'}"
      ></horizontal-nav-menu>
    </v-app-bar>

    <!-- place for additional app content -->
    <!-- <app-customizer class="d-none d-md-block"></app-customizer> -->

    <v-main>
      <app-content-container>
        <slot></slot>
      </app-content-container>
    </v-main>
    <v-overlay
      :value="$store.state.app.shallContentShowOverlay"
      z-index="6"
      absolute
      class="content-overlay"
    ></v-overlay>

    <v-footer
      v-if="footerType !== 'hidden'"
      app
      inset
      :absolute="footerType === 'static'"
      padless
      :color="footerType === 'static' ? 'transparent' : null"
    >
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
            href="https://nftiz.com"
            class="text-decoration-none"
          >NFTIZ</a><span class="d-none d-md-inline">, All rights Reserved</span></span>
          <div class="align-center d-none d-md-flex">
            <span></span>
            
          </div>
        </div>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import AppContentContainer from '@/layouts/components/app-content-container/AppContentContainer.vue'
import useLayoutConfig from '@/composable/useLayoutConfig'
import HorizontalNavMenu from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue'

import AppBarI18n from '@/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarUserMenu from '@/layouts/components/app-bar/AppBarUserMenu.vue'

import navMenuItems from '@/navigation/horizontal'

import { ref, watch, computed } from '@vue/composition-api'
import { getVuetify, useRouter } from '@/utils'

import themeConfig from '@themeConfig'
import { mdiHeartOutline } from '@mdi/js'

import AppLogo from '@/assets/images/svg/logo.svg'

export default {
  components: {
    AppContentContainer,
    AppLogo,
    HorizontalNavMenu,

    AppBarI18n,
    AppBarThemeSwitcher,
    AppBarUserMenu,
  },
  setup() {
    const shallShowFullSearch = ref(false)
    const { route } = useRouter()

    // eslint-disable-next-line object-curly-newline
    const { menuIsMenuHidden, appBarType, appBarIsBlurred, footerType, appContentWidth } = useLayoutConfig()

    const menuItems = computed({
      get: () => navMenuItems[route.value.meta.role],
    })

    console.log(menuItems)

    return {
      menuIsMenuHidden,
      appBarType,
      appBarIsBlurred,
      footerType,
      appContentWidth,

      menuItems,
      shallShowFullSearch,

      appName: themeConfig.app.name,

      icons: {
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/layouts/styles/_variables';
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.app-content-container {
  padding: $content-padding-horizontal-navigation-menu;
  &.app-content-container-boxed {
    padding: $boxed-content-padding-horizontal-navigation-menu;
  }
}

@include theme(v-application) using ($material) {
  .app-system-bar {
    box-shadow: 0 1px 0 0 map-deep-get($material, 'dividers');
  }
  .v-app-bar.navigation-menu {
    border-bottom: thin solid map-deep-get($material, 'dividers');
  }
}

.v-application {
  // System bar
  .app-system-bar {
    padding: 0 !important;
    // border-bottom: thin solid rgba(94, 86, 105, 0.14);
    // box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.09);

    &.app-system-bar-boxed {
      ::v-deep > div:first-child {
        padding: $boxed-content-padding-horizontal-navigation-menu !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    ::v-deep > div:first-child {
      padding-left: $content-padding-vertical-navigation-menu !important;
      padding-right: $content-padding-vertical-navigation-menu !important;
    }
  }

  // App Bar
  .v-app-bar {
    ::v-deep .v-toolbar__content {
      padding: 0;
    }

    .horizontal-nav-menu {
      padding-left: $content-padding-vertical-navigation-menu !important;
      padding-right: $content-padding-vertical-navigation-menu !important;

      &.horizontal-nav-menu-boxed {
        width: 1440px;
        padding: $boxed-content-padding-horizontal-navigation-menu !important;
      }
    }
  }

  // @media screen and (max-width: 1711px) {
  //   margin-left: 1.5rem !important;
  //   margin-right: 1.5rem !important;
  // }

  // Footer
  .v-footer > div {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    // Padding value is from `$boxed-content-padding-horizontal-navigation-menu`
    // We will keep top and buttom padding value of footer as it is
    padding-left: 3rem;
    padding-right: 3rem;

    @at-root .content-layout.content-full {
      .v-footer > div {
        max-width: unset;
        padding-left: $content-padding-vertical-navigation-menu !important;
        padding-right: $content-padding-vertical-navigation-menu !important;
      }
    }
  }
}
</style>
