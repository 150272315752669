import { ADVERTISER, PUBLISHER } from '@/utils/constants';

export const validateUserByRole = (userdata, role) => {
  let valid = false;

  switch(role) {
    case ADVERTISER:
      valid = userdata.project && !!userdata.project._id;
      break;
    case PUBLISHER:
      const { email, username, channel, specialization } = userdata;

      valid = email && username && channel && specialization;
      break;
    default:
      break;
  }
  
  return valid;
};
