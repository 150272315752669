import axios from "axios";
import Vue from "vue";
import VueAxios from "vue-axios";

import { AUTH_TOKEN } from '@/utils/constants';

axios.interceptors.request.use(
  config => {
    config.headers['Authorization'] = `Token ${localStorage.getItem(AUTH_TOKEN)}`;

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URI || "https://api.nftiz.com";
