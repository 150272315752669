<template>
  <div class="ps-6 pe-5 py-6">
     <role-switcher></role-switcher>
  </div>
</template>

<script>
import RoleSwitcher from '@/components/ui/role-switcher/RoleSwitcher'

export default {
  components: {
    RoleSwitcher,
  },
}
</script>
